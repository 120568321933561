<template>
    <div>
        <full-overlay :show="loading" />
        <b-table :items="list" :fields="fields" striped>
            <template #cell(settings)="{ item: { id } }">
                <b-button-group>
                    <b-button @click="content(id)">Описание</b-button>
                </b-button-group>
            </template>
        </b-table>
        <content-editor
            ref="editor"
            get-url="/page/:id"
            send-url="/page/:id"
            :media-url="poster"
        />
    </div>
</template> 

<script>
import FullOverlay from "@c/FullOverlay.vue";
import ContentEditor from "@c/ContentEditor.vue";
export default {
    name: "Page",
    components: {
        FullOverlay,
        ContentEditor,
    },
    data() {
        return {
            loading: false,
            list: [],
            poster: "",
            fields: [
                {
                    key: "id",
                    label: "#",
                },
                {
                    key: "name",
                    label: "Имя",
                },
                {
                    key: "settings",
                    label: "",
                },
            ],
        };
    },
    mounted() {
        this.getList();
    },
    methods: {
        content(id) {
            this.$refs.editor.open(id);
        },
        async getList() {
            this.loading = true;
            const { list, poster } = await this.$fetchAdmin(`/page`).then((r) =>
                r.json()
            );
            this.list = list;
            this.poster = `${this.$store.state.api}${poster}`;
            this.loading = false;
        },
    },
};
</script>